<template>
    <div>
        <b-form role="form" @submit.prevent="onSubmit">
            <b-row>
                <b-col>
                    <b-form-group id="input-group-2" label="Old Password:" label-for="input-2">
                        <b-form-input
                            id="input-2"
                            v-model="model.oldpassword"
                            type="password"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="12">
                    <b-form-group id="input-group-2" label="New Password:" label-for="input-2">
                        <b-form-input
                            id="input-2"
                            v-model="model.newpassword"
                            type="password"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="col-md-12 alert alert-danger" style="text-align: center;" v-if="errorMessage"
                 v-html="errorMessage"></div>

            <div class="mt-3">

                <b-button type="submit" variant="success">
                    Save
                </b-button>

            </div>
        </b-form>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'ChangePassword',
    props: {
        user_id: { type: String, required: true }
    },
    components: {},
    data () {
        return {
            errorMessage: '',
            model: {
                id: this.user_id,
                oldpassword: '',
                newpassword: ''
            }
        };
    },
    methods: {
        onSubmit () {
            const component = this;
            axios.form(urls.auth.ChangePassword, component.model).then(function (data) {
                const json = data.data;
                if (json.Success) {
                    component.$emit('success');
                } else if (json.Error) {
                    alert('');
                    if (json.errors) {
                        this.errorMessage = json.errors;
                    } else {
                        this.errorMessage = json.message;
                    }
                } else {}
            });
        }
    }
};
</script>

<style scoped>

</style>
