<template>
  <footer class="footer ">
    <b-row align-v="center" class="justify-content-lg-between">
      <b-col >
        <div class="copyright text-center text-center ">
          © {{year}} Xeoscript Technologies
        </div>
      </b-col>
    </b-row>
  </footer>
</template>
<script>
export default {
    data () {
        return {
            year: new Date().getFullYear()
        };
    }
};
</script>
<style></style>
